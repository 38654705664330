<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <b-sidebar
      lazy
      id="sidebar-backdrop"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
    >
      <add-user-to-vendor-form
        :vendorId="this.$route.params.id"
        :reloadParent="load"
        :closeSidebar="closeSidebar"
      />
    </b-sidebar>

    <!-- <b-sidebar
      lazy
      id="sidebar-questionnaire"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
    >
      <add-questionnaire-to-vendor-form
        :vendorId="this.$route.params.id"
        :reloadParent="load"
        :closeSidebar="closeQuestionnaireSidebar"
      />
    </b-sidebar> -->

    <b-sidebar
      lazy
      id="sidebar-tpr-assessment"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
    >
      <add-assessment-to-vendor-form
        :vendorId="this.$route.params.id"
        :reloadParent="load"
        :closeSidebar="closeAssessmentSidebar"
      />
    </b-sidebar>
    <div class="card">
      <div class="card-header" v-if="vendor">
        <div class="d-flex flex-column align-items-start justify-content-start">
          <h2 class="mb-1 text-primary">{{ vendor.name }}</h2>
          <p
            class="card-subtitle text-muted"
            v-if="vendor.created_at && vendor.created_at !== null"
          >
            Created at
            <span class="text-bold">{{ vendor.created_at | moment }} </span>
          </p>
        </div>
      </div>

      <div class="card-body">
        <b-tabs content-class="mt-2" align="start">
          <b-tab title="Users" :disabled="isUserVendor == true">
            <div class="card" v-if="vendor">
              <div class="card-header">
                <div
                  class="d-flex flex-column align-items-end justify-content-between"
                >
                  <b-button @click="handleNewUserClick" variant="success"
                    ><feather-icon class="mr-75" icon="PlusIcon" />Add User to
                    Vendor</b-button
                  >
                </div>
              </div>
              <div class="card-body" v-if="vendorUsers !== null">
                <h4>Assigned Users</h4>

                <table role="table" class="table">
                  <thead role="rowgroup">
                    <tr role="row">
                      <th role="columnheader" scope="col" class="text-start">
                        Name
                      </th>
                      <th role="columnheader" scope="col" class="text-start">
                        Email
                      </th>
                      <th role="columnheader" scope="col" class="text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody role="rowgroup">
                    <tr
                      v-for="user in vendorUsers"
                      :key="user._id"
                      role="row"
                      class="cursor-pointer"
                    >
                      <td aria-colindex="2" role="cell">
                        <div class="d-flex justify-content-start">
                          <p
                            v-if="user && user != null"
                            class="text-start text-primary font-weight-bold mb-0"
                          >
                            {{ user.firstname }} {{ user.lastname }}
                          </p>
                          <b-badge v-else variant="danger"
                            >Deleted User</b-badge
                          >
                        </div>
                      </td>

                      <td aria-colindex="2" role="cell">
                        <div class="d-flex justify-content-start">
                          <p class="text-start font-weight-bold mb-0">
                            {{ user.email }}
                          </p>
                        </div>
                      </td>

                      <td aria-colindex="2" role="cell">
                        <div
                          class="d-flex flex-column align-items-center justify-content-evenly"
                        >
                          <b-button
                            @click="handleUserRemoveClick(user)"
                            variant="danger"
                            size="sm"
                            ><feather-icon icon="XIcon" /><span class="ml-75"
                              >Remove</span
                            ></b-button
                          >
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  class="d-flex align-items-center justify-content-center w-100 pt-3"
                >
                  <b-pagination
                    v-model="vendorUsersFilters.page"
                    :per-page="vendorUsersFilters.perPage"
                    :total-rows="
                      vendorUsersFilters.totalPages * vendorUsersFilters.perPage
                    "
                    aria-controls="my-table"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Assessments" :active="isUserVendor == true">
            <div class="card" v-if="assessments !== null">
              <div class="card-header">
                <!-- <b-button
                  v-if="!isUserVendor"
                  @click="handleNewAssessmentClick"
                  variant="primary"
                  ><feather-icon class="mr-75" icon="PlusIcon" />New
                  Assessment</b-button
                > -->
              </div>
              <div class="card-body">
                <div>
                  <table role="table" class="table table-hover">
                    <thead role="rowgroup">
                      <tr role="row">
                        <th role="columnheader" scope="col">Name</th>
                        <th role="columnheader" scope="col">Description</th>
                        <th role="columnheader" scope="col" class="text-center">
                          Status
                        </th>
                        <th role="columnheader" scope="col" class="text-center">
                          Questionnaires
                        </th>
                        <th role="columnheader" scope="col" class="text-center">
                          Progress
                        </th>
                      </tr>
                    </thead>
                    <tbody role="rowgroup">
                      <tr
                        v-for="assessment in assessments"
                        :key="assessment._id"
                        role="row"
                        class="cursor-pointer"
                        @click="handleAssessmentClick(assessment._id)"
                      >
                        <td role="cell" style="width: 120px">
                          <div
                            class="d-flex justify-content-start"
                            style="width: 120px"
                            v-b-tooltip.hover.top.v-secondary
                            :title="assessment.title"
                          >
                            <p class="text-truncate font-weight-bolder mb-0">
                              {{ assessment.title }}
                            </p>
                          </div>
                        </td>

                        <td role="cell" style="width: 120px">
                          <div
                            class="d-flex justify-content-start"
                            style="width: 120px"
                            v-b-tooltip.hover.top.v-secondary
                            :title="assessment.description"
                          >
                            <p class="font-weight-bolder text-truncate mb-0">
                              {{ assessment.description }}
                            </p>
                          </div>
                        </td>

                        <td role="cell">
                          <div class="d-flex justify-content-center">
                            <b-badge
                              :variant="getStatusColor(assessment.status)"
                              >{{ getStatusNumber(assessment.status) }}</b-badge
                            >
                          </div>
                        </td>

                        <td role="cell">
                          <div class="d-flex justify-content-center">
                            <b-avatar
                              :text="assessment.total_questionnaires.toString()"
                              variant="light-primary"
                            />
                          </div>
                        </td>

                        <td role="cell">
                          <div class="d-flex justify-content-center">
                            <!-- <donut-bar
                              :percentage="
                                Number(assessment.completion.toFixed(2))
                              "
                              :displayValue="assessment.completion + '%'"
                              size="xsm"
                              :uniqueId="assessment._id + 2"
                              :colorsReversed="true"
                            /> -->
                            <progress-badge
                              :colorsReversed="true"
                              :value="assessment.completion"
                              :min="0"
                              :max="100"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div
                    class="w-100 d-flex align-items-center justify-content-center"
                  >
                    <b-pagination
                      class="mt-2"
                      v-model="assessmentFilters.page"
                      :per-page="assessmentFilters.perPage"
                      :total-rows="
                        assessmentFilters.totalPages * assessmentFilters.perPage
                      "
                      aria-controls="my-table"
                    ></b-pagination>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ResponseMixins from "../../mixins/ResponseMixins";
import ThirdPartyRisksMixins from "../../mixins/ThirdPartyRisksMixins";
import {
  BOverlay,
  BSidebar,
  BButton,
  BAvatar,
  BPagination,
  BTabs,
  BTab,
  BBadge,
  VBTooltip,
} from "bootstrap-vue";
import UtilsMixins from "../../mixins/UtilsMixins";
import AddUserToVendorForm from "./components/AddUserToVendorForm.vue";
import AddQuestionnaireToVendorForm from "./components/AddQuestionnaireToVendorForm.vue";
import DonutBar from "@/components/DonutBar.vue";
import ProgressBadge from "@/components/ProgressBadge.vue";

import TaskMixins from "../../mixins/TaskMixins";
import AddAssessmentToVendorForm from "./components/AddAssessmentToVendorForm.vue";
import UserMixins from "../../mixins/UserMixins";
import moment from "moment";

export default {
  components: {
    BOverlay,
    BSidebar,
    BButton,
    BAvatar,
    AddUserToVendorForm,
    AddQuestionnaireToVendorForm,
    AddAssessmentToVendorForm,
    DonutBar,
    BPagination,
    BTabs,
    BTab,
    BBadge,
    ProgressBadge,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },
  data() {
    return {
      showOverlay: false,
      vendor: null,
      importedQuestionnaire: null,
      assessments: null,
      isUserVendor: false,
      vendorUsers: null,
      vendorUsersFilters: {
        page: 1,
        perPage: 10,
        totalPages: 1,
      },
      assessmentFilters: {
        page: 1,
        perPage: 10,
        totalPages: 1,
      },
    };
  },

  mixins: [
    ThirdPartyRisksMixins,
    ResponseMixins,
    UtilsMixins,
    TaskMixins,
    UserMixins,
  ],

  watch: {
    vendorUsersFilters: {
      handler: function (newValue) {
        this.getVendorUsersAndSetData(newValue, this.$route.params.id);
      },
      deep: true,
    },
    assessmentFilters: {
      handler: function (newValue) {
        this.getAssessmentsOfVendorAndSetData(newValue, this.$route.params.id);
      },
      deep: true,
    },
  },

  mounted() {
    this.load();
  },
  methods: {
    load() {
      if (
        this.$store.state.app.user.user_type &&
        this.$store.state.app.user.user_type == "Vendor"
      ) {
        this.isUserVendor = true;
      } else {
        this.isUserVendor = false;
      }

      this.getVendorDetailsAndSetData(this.$route.params.id);
      this.getVendorUsersAndSetData(
        this.vendorUsersFilters,
        this.$route.params.id
      );
      this.getAssessmentsOfVendorAndSetData(
        this.assessmentFilters,
        this.$route.params.id
      );
    },

    getStatusNumber(num) {
      switch (num) {
        case 1:
          return "In Progress";
        case 2:
          return "Completed";
        default:
          return "Unknown";
      }
    },
    getStatusColor(num) {
      switch (num) {
        case 1:
          return "info";
        case 2:
          return "success";
        default:
          return "info";
      }
    },

    getVendorUsersAndSetData(params, vendorId) {
      this.showOverlay = true;
      this.getVendorUsers(params, vendorId)
        .then((res) => {
          this.vendorUsers = res.data.data.data;
          this.vendorUsersFilters.page = res.data.data.current_page;
          this.vendorUsersFilters.perPage = res.data.data.per_page;
          this.vendorUsersFilters.totalPages = res.data.data.last_page;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleAssessmentClick(id) {
      this.$router.push(
        `/thirdpartyrisks/${this.$route.params.id}/details/assessment/${id}`
      );
    },

    handleNewUserClick() {
      this.openSidebar();
    },
    handleUserRemoveClick(user) {
      this.showOverlay = true;
      this.setUserActiveStatus(user.user_id, 2)
        .then((res) => {
          this.handleResponse(res);
          this.load();
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getAssessmentsOfVendorAndSetData(filters, vendorId) {
      this.showOverlay = true;
      this.getAssessmentsOfVendor(filters, vendorId)
        .then((res) => {
          this.assessments = res.data.data.data;
          this.assessmentFilters.page = res.data.data.current_page;
          this.assessmentFilters.perPage = res.data.data.per_page;
          this.assessmentFilters.totalPages = res.data.data.last_page;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleNewAssessmentClick() {
      this.openAssessmentSidebar();
    },

    handleAddQuestionnaireClick() {
      this.openQuestionnaireSidebar();
    },

    getVendorDetailsAndSetData(id) {
      this.showOverlay = true;
      this.getVendorDetails(id)
        .then((res) => {
          this.vendor = res.data.data.data;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getImportedQuestionnaireAndSetData(vendorId) {
      this.showOverlay = true;
      this.getQuestionnaire({}, vendorId)
        .then((res) => {
          this.importedQuestionnaire = res.data.data.data;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    closeAssessmentSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-tpr-assessment");
    },
    openAssessmentSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-tpr-assessment");
      });
    },

    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
    openSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
      });
    },

    closeQuestionnaireSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-questionnaire");
    },
    openQuestionnaireSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-questionnaire");
      });
    },
  },
};
</script>

<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem !important;
}
</style>